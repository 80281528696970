<template>
  <div class="shifts-setting">
    <PageTitle
      title="排班班別設定"
      cyBtn1="new-shift-btn"
      btn="新增"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <el-input
        v-model="nameSearch"
        clearable
        placeholder="輸入排班班別名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table
        v-loading="loading.table"
        :data="tableData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="名稱" align="center" />
        <el-table-column prop="code" label="代號" align="center" />
        <el-table-column prop="color" label="顏色" align="center">
          <template slot-scope="scope">
            <div
              :style="
                `
            background: ${scope.row.color};
            width: 50%;
            height: 20px;
            margin: auto;
            `
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="操作"
          fixed="right"
          width="110"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'),
                     syncFormData(scope.row),
                     (selectRow = scope.row),
                     findShiftsClass()"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <AiHintBlock
        v-if="dialogType === 'create'"
        :hintLoading="hintLoading"
        @confirm="handleConfirm"
      />
      <el-form
        ref="form"
        v-loading="hintLoading"
        :model="formData"
        label-position="top"
        :rules="formRules"
      >
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" data-cy="name-input" placeholder="早班" />
        </el-form-item>
        <el-form-item label="顏色" prop="color">
          <el-select
            v-model="formData.color"
            data-cy="color-select"
            placeholder="請選擇班別顏色"
            no-data-text="無數據"
            class="color"
          >
            <el-option
              v-for="item in shiftColors"
              :key="item.name"
              :data-cy="item.name"
              :label="item.name"
              :value="item.name"
            >
              <div
                :style="
                  `margin:5px 0; background: ${item.code}; color: white; width:100%; height: 28px; border-radius:4px;`
                "
              />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代號" prop="code">
          <el-input v-model="formData.code" data-cy="code-input" placeholder="請輸入班別代號" />
        </el-form-item>
        <div class="mb-2">
          <el-button data-cy="add-period-btn" plain @click="addTimes">新增時段</el-button>
        </div>

        <!-- <el-form-item prop="times" label-width="0">
          <div
            v-for="(item, index) in formData.times"
            :key="index + item.start"
            class="flex align-items-stretch mb-2"
          > -->
        <!-- FIXME 防呆 根據參數決定間隔 -->
        <!-- <el-time-select
              v-model="item.start"
              data-cy="start-time-select"
              placeholder="起始時間"
              :picker-options="{
                start: '00:00',
                step: '00:10',
                end: useCrossDayShift ? '48:00' : '24:00',
              }"
            />
            <el-time-select
              v-model="item.end"
              data-cy="end-time-select"
              class="w-full ml-2"
              placeholder="結束時間"
              :picker-options="{
                start: item.start,
                step: '00:10',
                end: useCrossDayShift ? '48:00' : '24:00',
                minTime: item.start,
              }"
              @change="formData.lastEnd = item.end"
            />
            <el-button
              icon="el-icon-close"
              class="p-2 ml-2"
              @click="formData.times.splice(index, 1)"
            />
          </div>
        </el-form-item> -->

        <el-form-item prop="times" label-width="0">
          <div
            v-for="(item, index) in formData.times"
            :key="index + item.start"
            class="flex align-items-stretch mb-2"
          >
            <el-select
              v-model="item.start"
              class="w-full"
              clearable
              placeholder="起始時間"
              @change="item.end = ''"
            >
              <el-option
                v-for="time in times"
                :key="time"
                :label="time"
                :value="time"
              />
            </el-select>
            <el-select
              v-model="item.end"
              class="w-full ml-2"
              clearable
              placeholder="結束時間"
            >
              <el-option
                v-for="time in endTimes(item.start)"
                :key="time"
                :label="time"
                :value="time"
              />
            </el-select>
            <el-button
              icon="el-icon-close"
              class="p-2 ml-2"
              @click="formData.times.splice(index, 1)"
            />
          </div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button
          data-cy="dialog-cancel-btn"
          plain
          @click=";(showDialog = false), resetForm()"
        >
          取 消
        </el-button>
        <el-button data-cy="dialog-confirm-btn" type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete=";(deleteDialog = false), deleteShiftsClass()"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  CreateShiftsClass,
  GetShiftsClass,
  GetShiftsClassCount,
  DeleteShiftsClass,
  UpdateShiftsClass,
  FindShiftsClass,
} from '@/api/shiftsClass'
import { noEmptyRules, noEmptyTimeInterval } from '@/validation'
// Utils
// import { dialogTitle } from '@/utils/dialog'
// import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { timesConvert, plusZero } from '@/utils/helper'
// import { mapGetters } from 'vuex'
import { shiftColors } from '@/config/reservation'
import { find, map, get, set, keys, has } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { computed, ref, reactive, onMounted } from 'vue'
import { useTable } from '@/use/table'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'
export default {
  name: 'ShiftClassSetting',
  components: {
    DeleteDialog,
    EmptyBlock,
    AiHintBlock,
  },
  setup () {
    const { checkAction } = usePermissions()
    const formData = ref({
      name: '',
      code: '',
      color: '',
      times: [],
    })
    const formRules = reactive({
      name: noEmptyRules(),
      color: noEmptyRules(),
      code: noEmptyRules(),
      times: noEmptyTimeInterval(),
    })
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
    } = useTable()
    const form = ref(null)
    const { configurationHint, hintLoading, setData } = useAi()
    const dialogType = ref('create')
    const showDialog = ref(false)
    const nameSearch = ref('')
    const selectRow = ref(null)
    const selectShiftClass = ref(null)
    const deleteDialog = ref(false)
    const dialogTitle = computed(() => {
      if (dialogType.value === 'create') return '新增排班班別'
      if (dialogType.value === 'update') return '編輯排班班別'
      return ''
    })

    const useCrossDayShift = computed(() => checkAction('admin.appointmentScheduleDay.allowTimeCrossDay'))
    const times = computed(() => {
      const timesArray = []
      const limit = useCrossDayShift.value ? 48 * 6 + 1 : 24 * 6 + 1

      for (let i = 0; i < limit; i++) {
        const hour = plusZero(Math.floor(i / 6))
        const minute = (i % 6) * 10
        let label = `${hour}:${plusZero(minute)}`

        if (useCrossDayShift.value && hour >= 24) {
          label = `${plusZero(hour - 24)}:${plusZero(minute)}(隔日)`
        }

        timesArray.push(label)
      }

      return timesArray
    })
    const endTimes = (start) => {
      if (!start) {
        return times.value
      }
      const startIndex = times.value.indexOf(start)
      return times.value.slice(startIndex + 1)
    }
    const handleConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'appointmentScheduleDay.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData.value, res)
    }
    const checkForm = async () => {
      return await formUtils.checkForm(form.value)
    }
    const resetForm = () => {
      formUtils.resetForm(form.value)
      formData.value = {
        name: '',
        code: '',
        color: '',
        times: [],
      }
    }
    const dialogConfirm = async () => {
      if (!(await checkForm())) return
      const type = dialogType.value
      const payload = {
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
        name: get(formData.value, 'name'),
        code: get(formData.value, 'code'),
        color: find(shiftColors, { name: get(formData.value, 'color') }) ? find(shiftColors, { name: get(formData.value, 'color') }).code : get(formData.value, 'color'),
        times: map(get(formData.value, 'times'), (item) => ({
          start: transformTime(item.start),
          end: transformTime(item.end),
        })),
      }
      if (type === 'create') createShiftsClass(payload)
      if (type === 'update') updateShiftsClass(payload)
      resetForm()
      showDialog.value = false
    }

    const createShiftsClass = async (payload) => {
      try {
        await CreateShiftsClass(payload)
        await refresh()
      } catch (error) {
        window.$message.error(`新增失敗：${error || '未知原因'}`)
        console.log('錯誤', error)
      }
    }
    const updateShiftsClass = async (payload) => {
      try {
        await UpdateShiftsClass(payload)
        await refresh()
        window.$message.success('更新成功!')
      } catch (error) {
        window.$message.error(`更新失敗：${error || '未知原因'}`)
      }
    }

    const refresh = async () => {
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: nameSearch.value === '' ? undefined : nameSearch.value,
      }
      await Promise.allSettled([
        getShiftsClass(payload),
        getShiftsClassCount(payload),
      ])
    }
    const getShiftsClass = async (payload) => {
      try {
        const res = await GetShiftsClass(payload)
        tableData.value = res
      } catch (error) {
        console.log(error)
        window.$message.warning(error)
      }
    }
    const getShiftsClassCount = async (payload) => {
      try {
        const res = await GetShiftsClassCount(payload)
        tableDataCount.value = res.count
      } catch (error) {
        console.log(error)
        window.$message.warning(error)
      }
    }

    const transformTime = (time) => {
      if (time.includes('(隔日)')) {
        const [hour, minute] = time.replace('(隔日)', '').split(':')
        const newHour = parseInt(hour, 10) + 24
        return `${newHour}:${minute}`
      }
      return time
    }
    const syncFormData = (row) => {
      set(formData.value, 'name', row.name)
      set(formData.value, 'code', row.code)
      const color = find(shiftColors, { code: row.color })
      set(formData.value, 'color', color ? color.name : '')
    }
    const findShiftsClass = async () => {
      try {
        const res = await FindShiftsClass({
          shopId: shopId.value,
          id: get(selectRow.value, 'id'),
        })

        selectShiftClass.value = res
        set(formData.value, 'times', map(res.AppointmentScheduleDayTimes, (item) => {
          return {
            start: transformBackTime(timesConvert(item.start)),
            end: transformBackTime(timesConvert(item.end)),
          }
        }))
      } catch (error) {
        console.log(error)
        window.$message.warning(error)
      }
    }
    const deleteShiftsClass = async () => {
      try {
        await DeleteShiftsClass({
          shopId: get(selectRow.value, 'ShopId'),
          id: get(selectRow.value, 'id'),
        })

        await refresh()
        window.$message.success('刪除成功!')
      } catch (error) {
        window.$message.error(`創建失敗：${error || '未知原因'}`)
        console.log('錯誤', error)
      }
    }
    const transformBackTime = (time) => {
      const [hour, minute] = time.split(':')
      let newHour = parseInt(hour, 10)
      let nextDay = ''
      if (newHour >= 24) {
        newHour -= 24
        nextDay = '(隔日)'
      }
      return `${newHour.toString().padStart(2, '0')}:${minute}${nextDay}`
    }
    const openDialog = (type) => {
      dialogType.value = type
      showDialog.value = true
    }
    const addTimes = () => {
      const times = get(formData.value, 'times')
      let start
      if (times.length === 0) {
        start = ''
      } else {
        start = times[times.length - 1].end || ''
      }
      times.push({ start, end: '' })
    }
    onMounted(async () => {
      await refresh()
    })
    return {
      useCrossDayShift,
      times,
      endTimes,
      configurationHint,
      formData,
      hintLoading,
      dialogConfirm,
      form,
      showDialog,
      nameSearch,
      loading,
      tableData,
      tableDataCount,
      shiftColors,
      refresh,
      tableOptions,
      pageStartIndex,
      checkForm,
      resetForm,
      dialogType,
      createShiftsClass,
      updateShiftsClass,
      transformTime,
      syncFormData,
      findShiftsClass,
      selectShiftClass,
      selectRow,
      deleteShiftsClass,
      deleteDialog,
      openDialog,
      addTimes,
      formRules,
      dialogTitle,
      handleConfirm,
    }
  },
}
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply w-auto;
}
</style>
